import { Stack, Typography } from "@mui/material";
import React from "react";
import { RHFMultiCheckbox } from "src/components/hook-form";

type MultiCheckboxFilterPropsType = {
  options: string[];
  name: string;
  label?: string;
};

const MultiCheckboxFilter = ({ options, name, label }: MultiCheckboxFilterPropsType) => {
  return (
    <Stack spacing={1}>
      {label && <Typography variant='subtitle1'> {label} </Typography>}
      <RHFMultiCheckbox name={name} options={options || []} sx={{ width: 1 }} />
    </Stack>
  );
};

export default MultiCheckboxFilter;
