import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import Iconify from 'src/components/iconify/Iconify';

const DataCardViewer = ({ data }: { data: any }) => {
  const dataRendered = Object.entries(data || {}).map((e: any) => (
    <Accordion key={e[0]}>
      <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}>
        <Typography variant="subtitle1">{e[0]}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{e[1]}</Typography>
      </AccordionDetails>
    </Accordion>
  ));
  return (
    <div>
      <Box>{dataRendered}</Box>
    </div>
  );
};

export default DataCardViewer;
