import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import Image from 'src/components/image/Image';
import image from '../../assets/landing-page/Automated dashboard.png';

const AutomatedDB = () => {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column-reverse', md: 'row' },
        alignItems: 'center',
        margin: { xs: 2, md: 10 },
        marginY: { xs: 8, md: 10 },
        gap: { xs: 4, md: 10 },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '30px',
          alignItems: { xs: 'center', md: 'baseline' },
          width: { xs: '100%', md: '50%' },
        }}
      >
        <Typography
          variant="mainSectionTitle"
          sx={{
            fontSize: { xs: '32px', md: '64px' },
            lineHeight: { xs: 1.3, md: 1.2 },
            textAlign: { xs: 'center', md: 'left' },
            width: { xs: '100%', md: 'auto' },
          }}
        >
          Automated <br /> Dashboards <br /> for Every Muslim
        </Typography>
        <Typography
          variant="subtitle1"
          paragraph
          sx={{
            fontSize: { xs: '26px', md: '34px' },
            fontWeight: 400,
            color: '#848484',
            textAlign: { xs: 'center', md: 'left' },
            width: { xs: '100%', md: 'auto' },
          }}
        >
          Access our automated dashboards to <br /> visualize and analyze trending topics about
          <br />
          islam worldwide
        </Typography>
        <Button color="info" variant="contained" size="large" sx={{padding: "10px 100px", fontSize: 21}} onClick={()=>navigate('/radar')}>
          Try now
        </Button>
      </Box>
      <Box sx={{ width: { xs: '100%', md: '50%' } }}>
        <Image src={image} width="100%" />
      </Box>
    </Box>
  );
};

export default AutomatedDB;
