/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/return-await */
/* eslint-disable arrow-body-style */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios/index';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useSnackbar } from 'src/components/snackbar';
import {
  IDeleteParams,
  IGetItemParams,
  IGetListParams,
  IPatchParams,
  IPostParams
} from 'src/types/api';
import axiosInstance from 'src/utils/axios';
import { axiosApi } from './axios';

const useApiServices = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { logout } = useAuthContext()

  // Get list service
  const useGetListApi = <T,>({
    parameters,
    url,
    onSuccess,
    enabled
  }: IGetListParams) => {
    return useQuery<T, AxiosError>(
      [url, parameters],
      async () => await axiosApi.get(url, { params: parameters }),
      {
        onSuccess,
        onError: (data: any) => {
          if (data?.statusCode === 403) logout()
        },
        enabled,
      }
    );
  };

  // Get item service
  const useGetItemApi = <T,>({
    url,
    id,
    onSuccess,
    onError,
    enabled
  }: IGetItemParams) => {
    return useQuery<T, AxiosError>(
      [url],
      async () => await axiosApi.get(`${url}${id ? `/${id}` : ''}`),
      {
        onSuccess,
        onError,
        enabled,
      }
    );
  };

  // Delete service
  const useDeleteApi = <T,>({
    url,
    urlAfterSuccess,
    onSuccess,
    onError,
    withSuccessNotistack = true,
    withErrorNotistack = true,
  }: IDeleteParams) => {
    const queryClient = useQueryClient();
    return useMutation<T, AxiosError, T>(
      (id) => axiosApi.delete(`${url}/${id}`),
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries([urlAfterSuccess ?? url]);
          withSuccessNotistack && handleApiSuccesswithNotistack();
          onSuccess && onSuccess(data);
        },
        onError: (error) => {
          withErrorNotistack && handleApiErrorwithNotistack(error);
          onError && onError(error);
        },
      }
    );
  };

  // Post service
const usePostApi = <T, U>(
  { url, urlAfterSuccess, onSuccess, onError }: IPostParams
) => {
  const queryClient = useQueryClient();
  return useMutation<T, AxiosError, U>(
    (requestData: U) => {
      return axiosApi.post(url, requestData);
    },
    {
      onSuccess: (data: T) => {
        queryClient.invalidateQueries([urlAfterSuccess ?? url]);
        onSuccess && onSuccess(data);
      },
      onError: (error: AxiosError) => {
        onError && onError(error);
      },
    }
  );
};

  // Patch service
  const usePatchApi = <T,>({
    url,
    id,
    urlAfterSuccess,
    onSuccess,
    onError,
    withSuccessNotistack = true,
    withErrorNotistack = true,
  }: IPatchParams) => {
    const queryClient = useQueryClient();
    return useMutation<T, AxiosError, T>(
      (requestData) => axiosApi.patch(`${url}/${id}`, requestData),
      {
        onSuccess: () => {
          queryClient.invalidateQueries([urlAfterSuccess ?? url]);
          withSuccessNotistack && handleApiSuccesswithNotistack();
          onSuccess && onSuccess();
        },
        onError: (error) => {
          withErrorNotistack && handleApiErrorwithNotistack(error);
          onError && onError(error);
        },
      }
    );
  };

  // Download csv file
  const useDownloadCsv = <T, U>({
    url: csvUrl,
    urlAfterSuccess,
    onSuccess,
    onError,
    withSuccessNotistack = true,
    withErrorNotistack = true
    }: IPostParams) => {
    const queryClient = useQueryClient();
    return useMutation<T, AxiosError, U>(
      (filters) => {
        return axiosInstance({
          url: csvUrl,
          method: "post",
          responseType: "blob", // Set the response type to 'blob'
          headers: { Authorization: localStorage.getItem("accessToken") },
          data: { filters },
        });
      },
      {
        onSuccess: (data: T) => {
          queryClient.invalidateQueries([urlAfterSuccess ?? csvUrl]);
          withSuccessNotistack && handleApiSuccesswithNotistack();
          const url = window.URL.createObjectURL(new Blob([(data as any).data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "your-csv-file.csv"); // Set the filename for the download
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
          onSuccess && onSuccess();
        },
        onError: (error: AxiosError) => {
          withErrorNotistack && handleApiErrorwithNotistack(error);
          onError && onError(error)
        }
      }
    );
  };

  // For show snackbar when api return a error
  const handleApiErrorwithNotistack = (error: AxiosError) => {
    enqueueSnackbar({ variant: 'error', message: error?.message });
  };

  // For show snackbar when api successfully
  const handleApiSuccesswithNotistack = () => {
    enqueueSnackbar({ variant: 'success', message: 'Completed Successfully' });
  };

  return {
    useGetListApi,
    useGetItemApi,
    usePostApi,
    usePatchApi,
    useDeleteApi,
    useDownloadCsv
  };
};

export default useApiServices;
