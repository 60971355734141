/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Card } from "@mui/material";
import { scaleLog } from "@visx/scale";
import { Text } from "@visx/text";
import Wordcloud from "@visx/wordcloud/lib/Wordcloud";
import "src/assets/fonts/fonts.css";

interface ExampleProps {
  width?: number;
  height?: number;
  data: WordData[];
  xs?: number;
  md?: number;
}

export interface WordData {
  text: string;
  value: number;
}

const colors = ["#04297A", "#2F6B9A", "#7A0C2E", "#005249"];

export default function WordCloudChart ({
  width,
  height,
  data: words,
  xs,
  md,
}: ExampleProps) {
  const fontScale = scaleLog({
    domain: [Math.min(...(words || []).map(w => w.value)), Math.max(...(words || []).map(w => w.value))],
    range: [10, 50],
  });

  const fontSizeSetter = (datum: WordData) => fontScale(datum.value);

  return (
    <Card>
      <div className='wordcloud'>
        <Wordcloud
          words={words}
          width={width!}
          height={height!}
          fontSize={fontSizeSetter}
          font='cairo'
          padding={3}
          fontWeight='bold'
          spiral='archimedean'
          rotate={() => (Math.random() > 0.5 ? 90 : 0)}
          random={Math.random}
        >
          {cloudWords =>
            cloudWords?.map((w, i) => (
              <Text
                key={w.text}
                fill={colors[i % colors.length]}
                textAnchor='middle'
                transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
                fontSize={w.size}
                fontFamily={w.font}
              >
                {w.text}
              </Text>
            ))
          }
        </Wordcloud>
      </div>
    </Card>
  );
}
