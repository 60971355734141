import { useContext, useRef } from 'react';
import Typography from '@mui/material/Typography';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Button, AppBar, Toolbar, Container, BoxProps } from '@mui/material';
// hooks
import { AuthContext } from 'src/auth/JwtContext';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
// utils
import { bgBlur } from '../../utils/cssStyles';
// config
import { HEADER } from '../../config-global';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
// routes

// ----------------------------------------------------------------------

export default function Header() {
  const carouselRef = useRef(null);

  const theme = useTheme();
  const isDesktop = useResponsive('up', 'md');
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { logout } = useAuthContext();
  const handleLogout = async () => {
    try {
      logout();
      navigate(PATH_AUTH.login, { replace: true });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const navConfig = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: 'Radar',
      path: '/radar',
    },
    {
      title: 'Main Dashboard',
      path: '/dashboard',
    },
  ];

  return (
    <AppBar ref={carouselRef} sx={{ boxShadow: 0, backgroundColor: '#ffffff' }}>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default }),
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16,
            },
          }),
        }}
      >
        <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
          <Typography variant="h5" fontWeight={900} color="primary">
            Adam Radar
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          {isDesktop && <NavDesktop isOffset={isOffset} data={navConfig} />}
            <Button variant="outlined" color="primary" onClick={handleLogout}>
              {context?.isAuthenticated ? 'Logout' : 'Login'}
            </Button>
          {!isDesktop && <NavMobile isOffset={isOffset} data={navConfig} />}
        </Container>
      </Toolbar>

      {isOffset && <Shadow />}
    </AppBar>
  );
}

// ----------------------------------------------------------------------

function Shadow({ sx, ...other }: BoxProps) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
