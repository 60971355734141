import { Box, Button } from '@mui/material'
import React from 'react'
import Iconify from 'src/components/iconify/Iconify'

type FilterSectionButtonPropsType = {
  onClick: () => void
  label: string
  icon: string
}

const FilterSectionButton = ({ onClick, label, icon }: FilterSectionButtonPropsType) => {
  return (
    <Box sx={{ p: 2.5 }}>
      <Button
        
        fullWidth
        size="large"
        type="submit"
        color="inherit"
        variant="outlined"
        onClick={onClick}
        startIcon={<Iconify icon={icon} />}
      >
        {label}
      </Button>
    </Box>
  )
}

export default FilterSectionButton