import { Grid } from '@mui/material';
import { PieChartPropsType } from 'src/types/dashboardSectionsPropsTypes';
import { PieChart } from '../@dashboard/general/app';


const DashboardPieChart = ({data, theme, xs, md}: PieChartPropsType) => {
  return (
    <Grid item xs={xs || 12} md={md || 4} display='flex'>
      <PieChart
        style={{width: '100%'}}
        title={data?.title || ''}
        chart={{
          colors: [
            theme.palette.primary.main,
            theme.palette.info.main,
            theme.palette.error.main,
            theme.palette.warning.main,
          ],
          series: [...(data?.content || '')],
        }}
      />
    </Grid>
  );
};

export default DashboardPieChart;
