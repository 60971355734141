import { Grid, InputAdornment, TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import Iconify from 'src/components/iconify/Iconify';

const KeywordTextInput = ({ control }: { control: any }) => {
  return (
    <Grid item xs={12} md={6}>
      <Controller
        name="keyword"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Grid>
  );
};

export default KeywordTextInput;
