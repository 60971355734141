import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AnalysisType, DashboardFilterType } from 'src/types/dashboardData'
import axiosInstance from 'src/utils/axios'
import axios from 'axios'
// utils
// @types

// ----------------------------------------------------------------------

export const getData = createAsyncThunk('adamRadar/getData', async (data: any, { signal }) => {
  // Create a cancel token for this request
  const source = axios.CancelToken.source()
  // If the signal is aborted then cancel the request
  signal.addEventListener('abort', () => {
    source.cancel()
  })

  // Determining request type
  let url = ''
  let requestBody = {}
  if (data.customDashboardRequestBody) {
    requestBody = data.customDashboardRequestBody
  } else if (data.applyFilters) {
    ;({
      applyFilters: { url },
    } = data)
    requestBody = {
      dataset: data.applyFilters.dataset,
      filters: {
        topicFilter: data?.applyFilters?.filters?.topicFilter?.length
          ? data?.applyFilters?.filters?.topicFilter
          : undefined,
        sentimentFilter: data?.applyFilters?.filters?.sentimentFilter?.length
          ? data?.applyFilters?.filters?.sentimentFilter
          : undefined,
        languageFilter: data?.applyFilters?.filters?.languageFilter?.length
          ? data?.applyFilters?.filters?.languageFilter
          : undefined,
        keywordFilter: data?.applyFilters?.filters?.keywordFilter?.length
          ? data?.applyFilters?.filters?.keywordFilter
          : undefined,
        startDate: data?.applyFilters?.filters?.startDate,
        endDate: data?.applyFilters?.filters?.endDate,
      },
    }
  } else {
    ;({ url } = data)
    requestBody = {
      source: 'main',
    }
  }

  try {
    const res = await axiosInstance.post(url.toLowerCase(), requestBody, {
      cancelToken: source.token,
    })
    return res.data
  } catch (error) {
    console.log(error)
    if (error.response && error.response.status === 404) {
      console.log(error)
      return error
    }
    throw error // Rethrow other errors
  }
})

export type adamDataType = {
  isLoading: boolean
  error: Error | string | null
  data: AnalysisType
  platformName: string
  dashboardFilter: DashboardFilterType
  csvDataSet: any
}

export type mainResponseDataType = {
  analysis: any
  dashboardFilter: any
  dataset: any
  platform: string
  status: boolean
}

const initialState: adamDataType = {
  isLoading: false,
  error: null,
  data: [] as any,
  platformName: '',
  dashboardFilter: {} as any,
  csvDataSet: [],
}

const slice: any = createSlice({
  name: 'adamData',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.pending, state => {
        state.isLoading = true
        state.error = null
      })
      .addCase(getData.rejected, (state, action) => {
        state.isLoading = action?.error?.name?.toLowerCase() === 'aborterror'
        state.data = [] as any
        state.platformName = 'Not found'
        state.dashboardFilter = [] as any
        state.error = +(action?.error?.message! || '') as any
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.isLoading = false
        state.data = action.payload.analysis
        state.platformName = action.payload.platform || ''
        state.dashboardFilter = action.payload.dashboardFilter || state.dashboardFilter
        state.csvDataSet = action.payload.dataset || state.csvDataSet
      })
  },
})

export const optionsSelector = (state: any) => state.adamRadar.dashboardFilter
export const dataSelector = (state: any) => state.adamRadar

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------
