import { Grid } from "@mui/material";
import { DashboardLineChartPropsType } from "src/types/dashboardSectionsPropsTypes";
import { LineChart } from "../@dashboard/general/app";

const DashboardLineChart = ({ data, xs, md }: DashboardLineChartPropsType) => {
  return (
    <Grid item xs={xs} md={md} display='flex'>
      <LineChart
        style={{ width: "100%" }}
        title={data?.title}
        subheader=''
        chart={{
          categories: [...(data?.content?.xAxis || "")],
          series: [
            {
              year: "2019",
              data: [
                {
                  name: "Number Of Questions",
                  data: [...(data?.content?.yAxis || "")],
                },
              ],
            },
          ],
        }}
      />
    </Grid>
  );
};

export default DashboardLineChart;
