import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller } from "react-hook-form";

type startDateFilterPropsType = {
  control: any;
  dashboardFilter: any;
};

const StartDateFilter = ({ control, dashboardFilter }: startDateFilterPropsType) => {
  console.log(dashboardFilter);
  return (
    <Controller
      name='dateRangeFilter.start'
      control={control}
      render={({ field }) => {
        console.log(field);
        return (
          <DatePicker
            inputFormat='dd-MM-yyyy'
            label='Start date'
            value={field.value || dashboardFilter?.dateRangeFilter?.start}
            onChange={field.onChange}
            renderInput={(params: any) => <TextField {...params} />}
            minDate={dashboardFilter?.dateRangeFilter?.start}
            maxDate={dashboardFilter?.dateRangeFilter?.end}
          />
        );
      }}
    />
  );
};

export default StartDateFilter;
