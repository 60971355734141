import { Card, CardHeader, Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import MultiSelectDropDown from "./MultiSelectDropDown";
import CustomEndDate from "./Search-bar-sections/CustomEndDate";
import CustomStartDate from "./Search-bar-sections/CustomStartDate";
import KeywordTextInput from "./Search-bar-sections/KeywordTextInput";
import SearchBarButtons from "./Search-bar-sections/SearchBarButtons";

type PlatformType = {
  data: {
    label: string;
    value: string;
  }[];
  label: string;
  name: string;
};

type SearchBarPropsType = {
  setCurrentTab: any;
  refetch: any;
  currentTab: string;
};

const SearchBar = ({ setCurrentTab, refetch, currentTab }: SearchBarPropsType) => {
  const { watch, control, reset } = useFormContext();
  const { useGetListApi } = useApiServices();

  const { data, isLoading } = useGetListApi<ResDataType["platforms"]>({ url: API_URLS.PLATFORMS });

  return (
    <AppLoadingAndErrorWrapper showLinear isLoading={isLoading} errorMessage={null}>
      <Card sx={{ padding: "10px", marginY: "10px" }}>
        <CardHeader title='Search Bar' />
        <Grid container spacing={2} marginY={2}>
          <KeywordTextInput control={control} />
          <CustomStartDate control={control} />
          <CustomEndDate control={control} />
          {data?.data.map((platform: PlatformType) => (
            <Grid key={platform?.name} item xs={12} sm={6}>
              <MultiSelectDropDown
                label={platform?.label}
                data={platform?.data}
                name={platform?.name}
                control={control}
              />
            </Grid>
          ))}
          <SearchBarButtons reset={reset} />
        </Grid>
      </Card>
    </AppLoadingAndErrorWrapper>
  );
};

export default SearchBar;
