export const customDashboardTabs = [
  {
    label: 'Binbaz',
    value: 'binbaz',
  },
  {
    label: 'Islam QA',
    value: 'islamqa',
  },
  {
    label: 'Islam web',
    value: 'islamweb',
  },
  {
    label: 'Twitter',
    value: 'twitter',
  },
  {
    label: 'Youtube',
    value: 'youtube',
  },
  {
    label: 'Google trends',
    value: 'googletrends',
  },
  {
    label: 'Forbes',
    value: 'forbes',
  },
  {
    label: 'Medium',
    value: 'medium',
  },
  {
    label: 'The Mercury News',
    value: 'mercurynews',
  },
  {
    label: 'Quartz',
    value: 'quartz',
  },
  {
    label: 'NYC Times',
    value: 'nyctimes',
  },
];
