import axios from "axios";
// config
import { HOST_API_KEY } from "../config-global";

// -----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API_KEY });
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    throw new Error(error.response.data.detail);
  }
);

axiosInstance.interceptors.request.use(
  async (config) => {
    // const controller = new AbortController();

    const acccessToken = localStorage.getItem("accessToken");
    // const refreshToken = localStorage.getItem('refreshToken');

    // const { exp } = jwtDecode<{ exp: number }>(acccessToken!);

    // const currentTime = Date.now() / 1000;

    // if (!(exp > currentTime)) {
    //   const result = await fusionAuthClient.exchangeRefreshTokenForJWT({
    //     refreshToken: refreshToken ?? '',
    //   });
    //   if (result.response?.refreshToken && result.response?.token) {
    //     localStorage.setItem('accessToken', result.response.token);
    //     localStorage.setItem('refreshToken', result.response.refreshToken);
    //     if (config && config.headers) {
    //       config.headers.Authorization = `Bearer ${result.response.token!}`;
    //       config.headers['Content-Type'] = 'application/json';
    //     }
    //     return config;
    //   } else {
    //     return {
    //       ...config,
    //       signal: controller.signal,
    //     };
    //   }
    // } else {
    //   if (config && config.headers) {
    //     // @ts-ignore
    //     config.header = {
    //       Authorization: `Bearer ${acccessToken!}`,
    //       'Content-Type': 'application/json',
    //       ...config.headers,
    //     };
    //     config.headers.Authorization = `Bearer ${acccessToken!}`;
    //   }
    // }
    // @ts-ignore
    config.header = {
      // Authorization: `Bearer ${acccessToken!}`,
      "Content-Type": "application/json",
      ...config.headers,
      Authorization: `Bearer ${acccessToken!}`,
    };
    config.headers.Authorization = `Bearer ${acccessToken!}`;
    // config.headers.Authorization = `${acccessToken!}`;
    return config;
  },
  (error) => {
    return error;
  }
);

export default axiosInstance;
