export const customFiltersConfig: any = {
  radar: {
    source: 'custom',
    keyword: '',
    startDate: new Date(),
    endDate: new Date(),
    islamicWebsites: [],
    discussionPlatforms: [],
    newspaper: [],
    socialMedia: [],
    customTopicFilter: [],
    customLanguageFilter: [],
    customSentimentFilter: [],
  },
  islamqa: {
    topicFilter: [],
    languageFilter: [],
  },
  islamweb: {
    topicFilter: [],
    languageFilter: [],
  },
  binbaz: {
    topicFilter: [],
  },
  twitter: {
    languageFilter: [],
    sentimentFilter: [],
  },
  youtube: {
    languageFilter: [],
    sentimentFilter: [],
  },
  googletrends: {},
  nyctimes: {
    sentimentFilter: [],
    languageFilter: [],
  },
  quartz: {
    languageFilter: [],
    sentimentFilter: [],
  },
  mercurynews: {
    languageFilter: [],
    sentimentFilter: [],
  },
};

export const showCustomFilter = (platform: string, prop: string) =>
  Object?.prototype?.hasOwnProperty?.call(
    customFiltersConfig?.[platform?.toLowerCase()] || {},
    prop
  );
