import { Grid } from '@mui/material';
import { DashboardCardsType } from 'src/types/dashboardSectionsPropsTypes';
import { AppWidgetSummary } from '../@dashboard/general/app';

const DashboardCards = ({ cards }: DashboardCardsType) => {
  const cardSize = cards?.length > 2 ? 4 : 12 / (cards?.length || 1);
  const Cards = cards?.map((card: any) => (
    <Grid key={card?.title} item xs={6} md={cardSize} display='flex'>
      <AppWidgetSummary
        color="info"
        sx={{ minHeight: '150px', width: '100%' }}
        title={card?.title}
        info={card?.value}
      />
    </Grid>
  ));
  return <>{Cards}</>;
};

export default DashboardCards;
