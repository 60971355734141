import { Box, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import { useBoolean } from "src/hooks/use-boolean";
import MainFilterDrawer from "src/sections/@dashboard/main/MainFilterDrawer";
import { ForbesMainFiltersType } from "src/types/main-dashboards/newspaper/forbes";

type DashboardHeaderType = {
  reset: any;
  enableFetch?: boolean;
  isDownloadingCsv?: boolean;
  filterData?: {
    dashboardFilter: ForbesMainFiltersType;
  };
  downloadCsv: any;
  setParameters: Dispatch<SetStateAction<ForbesMainFiltersType>>;
};

const ForbesMainDashboardHeader = ({
  reset,
  enableFetch,
  filterData,
  downloadCsv,
  isDownloadingCsv,
  setParameters,
}: DashboardHeaderType) => {
  const openFilter = useBoolean();

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      marginBottom={2}
      flexDirection={{ xs: "column", md: "row" }}
      textAlign={{ xs: "center", md: "left" }}
      gap={{ xs: "10px", md: "2px" }}
    >
      <Typography variant='button' fontSize='30px'>
        Forbes
      </Typography>
      <MainFilterDrawer
        open={openFilter.value}
        onOpen={openFilter.onTrue}
        onClose={openFilter.onFalse}
        onResetFilter={reset}
        filters={filterData?.dashboardFilter!}
        setParameters={setParameters}
      />
      <AppLoadingButton
        label='Download CSV file'
        variant='outlined'
        onClick={downloadCsv}
        isLoading={!!isDownloadingCsv}
        fullWidth={false}
      />
    </Box>
  );
};

export default ForbesMainDashboardHeader;
