import { pxToRem } from 'src/theme/typography';
import styled from '@emotion/styled';
import { Box, Grid, Typography } from '@mui/material';
import Image from 'src/components/image/Image';
import workingProgressImage from '../../assets/landing-page/Working progress.png';

const WorkingProgress = () => {
  const paragraphs = [
    {
      title: 'Analysis.',
      description:
        'The goal of analysis is to gain insights into a subject or problem, often in order to make better decisions or to develop more effective solutions.',
    },
    {
      title: 'Strategy.',
      description:
        'Developing a strategy often involves analyzing the current situation, identifying potential risks or challenges.',
    },
    {
      title: 'Performance.',
      description:
        'Improving performance often involves identifying areas where improvements can be made, setting specific goals and targets, and implementing changes or interventions to improve results.',
    },
  ];

  const ParNumber = styled(Typography)({
    fontWeight: 700,
    fontSize: pxToRem(48),
    color: '#5db1ba',
    marginBottom: pxToRem(10),
  });

  return (
    <Box sx={{ py: { lg: 8, sm: 16 }, marginX: { xs: 0, sm: 4, md: 6, lg: 10 } }}>
      <Grid container alignItems="center">
        <Grid item xs={12} lg={6}>
          <Box sx={{ mb: { xs: 4, lg: 0 }, width: {xs: '75%', lg: '100%'}, margin: 'auto' }}>
            <Image src={workingProgressImage} alt="" />
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, alignItems:{xs: 'center', lg: 'flex-start'} }}>
            <Typography
              variant="h3"
              sx={{ fontWeight: 700, fontSize: { lg: 28, sm: 32 }, color: '#5db1ba' }}
            >
              How to do it
            </Typography>
            <Typography variant="h1" sx={{ fontWeight: 700, fontSize: { xs: 40, lg: 64 }, textAlign: {xs: 'center', lg: 'left'} }}>
              Our Working Progress
            </Typography>
            {paragraphs.map((par, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  gap: 2,
                  alignItems: { xs: 'center', lg: 'flex-start' },
                  flexDirection: { xs: 'column', lg: 'row' },
                }}
              >
                <ParNumber>{`0${index + 1}`}</ParNumber>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: { xs: 'center', lg: 'flex-start' },
                    textAlign: { xs: 'center', lg: 'left' },
                  }}
                >
                  <Typography variant="parTitle">{par.title}</Typography>
                  <Typography variant="parDescription" width="75%">
                    {par.description}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WorkingProgress;
