// ----------------------------------------------------------------------

function path (root: string, sublink: string) {
  return `${root}${sublink}`
}

const ROOTS_AUTH = '/auth'
export const ROOTS_DASHBOARD = '/dashboard'
export const PATH_AFTER_LOGIN = '/youtube'

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
}

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
}

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  radar: path(ROOTS_DASHBOARD, '/radar'),
  socialMedia: {
    twitter: path(ROOTS_DASHBOARD, '/main/Twitter'),
    youtube: path(ROOTS_DASHBOARD, '/youtube'),
    medium:  path(ROOTS_DASHBOARD, '/medium'),
    forbes:  path(ROOTS_DASHBOARD, '/forbes'),
    quartz:  path(ROOTS_DASHBOARD, '/quartz'),
    nycTimes:  path(ROOTS_DASHBOARD, '/nyc-times'),
    mercuryNews:  path(ROOTS_DASHBOARD, '/mercury-news'),
    facebook: path(ROOTS_DASHBOARD, '/main/facebook'),
    instagram: path(ROOTS_DASHBOARD, '/main/instagram'),
  },
  discussionPlatform: {
    reddit: path(ROOTS_DASHBOARD, '/main/reddit'),
    quora: path(ROOTS_DASHBOARD, '/main/quora'),
  },
  newsPaper: {
    TheMercuryNews: path(ROOTS_DASHBOARD, '/main/mercurynews'),
    quartz: path(ROOTS_DASHBOARD, '/main/quartz'),
    medium: path(ROOTS_DASHBOARD, '/main/medium'),
    forbes: path(ROOTS_DASHBOARD, '/main/forbes'),
    NYCTimes: path(ROOTS_DASHBOARD, '/main/nyctimes'),
  },
  islamicWebsites: {
    IbnBaz: path(ROOTS_DASHBOARD, '/main/binbaz'),
    IslamQA: path(ROOTS_DASHBOARD, '/main/IslamQA'),
    Islamweb: path(ROOTS_DASHBOARD, '/main/IslamWeb'),
  },
  googleTrends: path(ROOTS_DASHBOARD, '/main/googletrends'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
}