import { createSlice } from '@reduxjs/toolkit';
// utils
// @types

// ----------------------------------------------------------------------
export type adamDataType = {
  dataset: any;
};

const initialState: adamDataType = {
  dataset: {},
};

const slice = createSlice({
  name: 'dataset',
  initialState,
  reducers: {
    saveDataSet: (state: any, action) => {
      const { platform } = action.payload;
      state.dataset[platform] = action.payload.dataset;
    },
  },
});

export default slice.reducer;
export const { saveDataSet } = slice.actions; // Use destructuring to export the action correctly

// ----------------------------------------------------------------------
