// @mui
import { Stack, Typography } from '@mui/material';
// routes
import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';
import login_image from '../../assets/login.svg';

// ----------------------------------------------------------------------

export default function Login() {
  const title = (
    <Typography variant="h3" fontFamily="spectral" color="#ffffff" fontWeight={600}>
      Welcome back
      <Typography variant="body1" fontFamily="spectral" fontWeight={400}>
        Just a couple of clicks and we start
      </Typography>
    </Typography>
  );
  return (
    <LoginLayout title={title as any} illustration={login_image}>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Typography fontFamily='spectral' variant="h2">Sign In</Typography>
      </Stack>
      <AuthLoginForm />
    </LoginLayout>
  );
}
