import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Card, CardContent } from '@mui/material';
import CarouselCenterMode from 'src/sections/@landingPage/CarouselCenterMode';
import { nanoid } from '@reduxjs/toolkit';
import youtube from '../../assets/Carousel/youtube.svg';
import google_trends from '../../assets/Carousel/Google Trends.png';
import bin_baz from '../../assets/Carousel/site-logo.webp';
import reddit from '../../assets/Carousel/reddit.svg';
import twitter from '../../assets/Carousel/icons8-twitter.svg';
import medium from '../../assets/Carousel/medium.svg';
import mercury_news from '../../assets/Carousel/mercury news.svg';

function SocialMedia() {
  const data = [
    {
      image: google_trends,
      title: 'Google trends',
      width: '',
    },
    {
      image: bin_baz,
      title: 'Binbaz',
      width: '',
    },
    {
      image: youtube,
      title: 'Youtube',
      width: '150px',
    },
    {
      image: reddit,
      title: 'Reddit',
      width: '150px',
    },
    {
      image: twitter,
      title: 'twitter',
      width: '150px',
    },
    {
      image: medium,
      title: 'medium',
      width: '175px',
    },
    {
      image: mercury_news,
      title: 'mercury_news',
      width: '',
    },
  ].map((e) => {
    return { ...e, id: nanoid() };
  });
  return (
    <div className="social-media">
      <Card sx={{ backgroundColor: '#F3F3F3', borderRadius: 0 }}>
        <CardContent>
          <CarouselCenterMode data={data!} />
        </CardContent>
      </Card>
    </div>
  );
}

export default SocialMedia;
