import { ApexOptions } from "apexcharts";
import { useState } from "react";
// @mui
import { Card, CardHeader, Box, CardProps } from "@mui/material";
// components
import Chart, { useChart } from "../../../components/chart";

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  chart: {
    categories?: string[];
    colors?: string[];
    series: {
      type: string;
      data: {
        name: string;
        data: number[];
      }[];
    }[];
    options?: ApexOptions;
  };
  height?: string;
}

export default function BarChart ({ title, subheader, chart, height, ...other }: Props) {
  const { categories, colors, series, options } = chart;

  const [seriesData] = useState("Week");

  const chartOptions = useChart({
    colors,
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories,
    },
    tooltip: {
      y: {
        formatter: (value: number) => `${value}`,
      },
    },
    ...options,
  });

  return (
    <Card {...other} sx={{ height }}>
      <CardHeader title={title} subheader={subheader} />

      {series.map(item => (
        <Box key={item.type} sx={{ mt: 3, mx: 3 }} dir='ltr'>
          {item.type === seriesData && (
            <Chart type='bar' series={item.data} options={chartOptions} height={364} />
          )}
        </Box>
      ))}
    </Card>
  );
}
