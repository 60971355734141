import { yupResolver } from "@hookform/resolvers/yup";
import { Container, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import FormProvider from "src/components/hook-form/FormProvider";
import { useSettingsContext } from "src/components/settings";
import { YoutubeCustomFiltersSchema, ForbesMainFiltersSchema } from "src/schemas";
import DashboardBarChart from "src/sections/adam-main-dashboard/DashboardBarChart";
import DashboardCards from "src/sections/adam-main-dashboard/DashboardCards";
import DashboardLineChart from "src/sections/adam-main-dashboard/DashboardLineChart";
import DashboardPieChart from "src/sections/adam-main-dashboard/DashboardPieChart";
import DashboardTopWords from "src/sections/adam-main-dashboard/DashboardTopWords";
import DashboardWordCloud from "src/sections/adam-main-dashboard/DashboardWordCloud";
import DashboardMostViewedQ from "src/sections/adam-main-dashboard/ExpanderView";
import {
  YoutubeCustomFiltersType,
  YoutubeMainFiltersType,
} from "src/types/main-dashboards/social-media/youtube";
import { useQueryParams } from "use-query-params";
import YoutubeCustomDashboardHeader from "./YoutubeCustomDashboardHeader";

const YoutubeCustomDashboard = () => {
  // #region States and hooks
  const { usePostApi, useGetListApi, useDownloadCsv } = useApiServices();
  const { themeStretch } = useSettingsContext();
  const theme = useTheme();
  // Get filters default from url params if available
  const [query] = useQueryParams({
    keywordFilter: "",
    sentimentFilter: "",
    languageFilter: "",
    topicFilter: "",
    startDate: "",
    endDate: "",
  });
  // Query params
  const [parameters, setParameters] = useState<YoutubeCustomFiltersType>({
    keywordFilter: query.keywordFilter?.split(","),
    sentimentFilter: query.sentimentFilter?.split(","),
    languageFilter: query.languageFilter?.split(","),
  });
  // #endregion States and hooks

  // #region Filters form
  const filters = useForm<YoutubeCustomFiltersType>({
    defaultValues: {
      keywordFilter: query.keywordFilter?.split(","),
      sentimentFilter: query.sentimentFilter?.split(","),
      languageFilter: query.languageFilter?.split(","),
    },
    resolver: yupResolver(YoutubeCustomFiltersSchema),
  });
  // #endregion Filter form

  // #region Services
  const {
    data: platformData,
    mutate,
    isLoading: isDataLoading,
  } = usePostApi<ResDataType["youtubeMain"], { filters?: YoutubeMainFiltersType }>({
    url: API_URLS.YOUTUBE_MAIN,
  });

  const { data: filtersData, isLoading: isLoadingOptions } = useGetListApi<{
    dashboardFilter: YoutubeMainFiltersType;
  }>({
    url: API_URLS.YOUTUBE_MAIN_FILTERS,
    enabled: !!platformData,
  });

  const { mutate: downloadCsv, isLoading } = useDownloadCsv({ url: API_URLS.YOUTUBE_MAIN_CSV });

  const handleDownloadCsv = () => {
    downloadCsv({ filters: filters.watch() });
  };
  // #endregion Services

  useEffect(() => {
    mutate({
      filters: {
        ...filters.watch(),
      },
    });
  }, [mutate, filters]);

  return (
    <FormProvider methods={filters}>
      <YoutubeCustomDashboardHeader
        reset={filters.reset}
        filterData={filtersData!}
        downloadCsv={handleDownloadCsv}
        isDownloadingCsv={isLoading}
        setParameters={setParameters}
      />
      {/* <Container maxWidth={themeStretch ? false : "xl"}>
        <Helmet>
          <title>Adam Radar | Youtube dashboard</title>
        </Helmet>
        <AppLoadingAndErrorWrapper
          isLoading={isDataLoading || isLoadingOptions}
          errorMessage={null}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
            </Grid>
            <Grid item container spacing={3} xs={12}>
              {platformData?.analysis.cards?.length && (
                <DashboardCards cards={platformData?.analysis.cards} />
              )}
            </Grid>
            {platformData?.analysis.pieChart && (
              <DashboardPieChart
                theme={theme}
                data={platformData?.analysis.pieChart}
                xs={12}
                md={6}
              />
            )}
            {platformData?.analysis.lineChart && (
              <DashboardLineChart data={platformData?.analysis.lineChart} xs={12} md={6} />
            )}
            {platformData?.analysis.top5Words && (
              <DashboardTopWords data={platformData?.analysis.top5Words} xs={12} md={6} />
            )}
            {platformData?.analysis.barChart && (
              <DashboardBarChart
                theme={theme}
                data={platformData?.analysis.barChart}
                xs={12}
                md={6}
              />
            )}
            <Grid item xs={12} md={12}>
              <ParentSize>
                {({ width, height }) => (
                  <DashboardWordCloud
                    data={platformData?.analysis.wordCloudChart.content!}
                    xs={12}
                    md={12}
                    width={width}
                    height={600}
                  />
                )}
              </ParentSize>
            </Grid>
            {platformData?.analysis.insightsDetails &&
              platformData?.analysis.insightsDetails?.map((e, index) => {
                return (
                  <DashboardMostViewedQ
                    dataArr={platformData?.analysis.insightsDetails}
                    data={e}
                    index={index}
                  />
                );
              })}
          </Grid>
        </AppLoadingAndErrorWrapper>
      </Container> */}
    </FormProvider>
  );
};

export default YoutubeCustomDashboard;
