import { Grid, Stack } from '@mui/material';
import { DashboardBarChartType } from 'src/types/dashboardSectionsPropsTypes';
import BarChart from '../@dashboard/main/BarChart';

const DashboardBarChart = ({ data, theme, xs, md }: DashboardBarChartType) => {
  return (
    <Grid item xs={xs} md={md} display='flex'>
      <Stack spacing={3} width='100%'>
        <BarChart
          title={data?.title}
          height='100%'
          chart={{
            categories: [...(data?.content?.xAxis || '')],
            colors: [theme.palette.primary.main, theme.palette.warning.main],
            series: [
              {
                type: 'Week',
                data: [
                  {
                    name: 'Number of questions',
                    data: [...(data?.content?.yAxis || '')],
                  },
                ],
              },
            ],
          }}
        />
      </Stack>
    </Grid>
  );
};

export default DashboardBarChart;
