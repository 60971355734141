import * as Yup from "yup";

export const YoutubeMainFiltersSchema = Yup.object().shape({
  languageFilter: Yup.array().of(Yup.string()),
  dateRangeFilter: Yup.object().shape({
    startDate: Yup.string(),
    endDate: Yup.string(),
  }),
  keywordFilter: Yup.array().of(Yup.string()),
  sentimentFilter: Yup.array().of(Yup.string()),
});

export const MediumMainFiltersSchema = Yup.object().shape({
  languageFilter: Yup.array().of(Yup.string()),
  dateRangeFilter: Yup.object().shape({
    startDate: Yup.string(),
    endDate: Yup.string(),
  }),
  keywordFilter: Yup.array().of(Yup.string()),
  sentimentFilter: Yup.array().of(Yup.string()),
});

export const ForbesMainFiltersSchema = Yup.object().shape({
  languageFilter: Yup.array().of(Yup.string()),
  dateRangeFilter: Yup.object().shape({
    startDate: Yup.string(),
    endDate: Yup.string(),
  }),
  keywordFilter: Yup.array().of(Yup.string()),
  sentimentFilter: Yup.array().of(Yup.string()),
});

export const QuartzMainFiltersSchema = Yup.object().shape({
  languageFilter: Yup.array().of(Yup.string()),
  dateRangeFilter: Yup.object().shape({
    startDate: Yup.string(),
    endDate: Yup.string(),
  }),
  keywordFilter: Yup.array().of(Yup.string()),
  sentimentFilter: Yup.array().of(Yup.string()),
});

export const NYCTimesMainFiltersSchema = Yup.object().shape({
  languageFilter: Yup.array().of(Yup.string()),
  dateRangeFilter: Yup.object().shape({
    startDate: Yup.string(),
    endDate: Yup.string(),
  }),
  keywordFilter: Yup.array().of(Yup.string()),
  sentimentFilter: Yup.array().of(Yup.string()),
});

export const MercuryNewsMainFiltersSchema = Yup.object().shape({
  languageFilter: Yup.array().of(Yup.string()),
  dateRangeFilter: Yup.object().shape({
    startDate: Yup.string(),
    endDate: Yup.string(),
  }),
  keywordFilter: Yup.array().of(Yup.string()),
  sentimentFilter: Yup.array().of(Yup.string()),
});

export const YoutubeCustomFiltersSchema = Yup.object().shape({
  languageFilter: Yup.array().of(Yup.string()),
  keywordFilter: Yup.array().of(Yup.string()),
  sentimentFilter: Yup.array().of(Yup.string()),
});
