import { Dispatch, SetStateAction, useEffect } from "react";
// form
import { useFormContext } from "react-hook-form";
// types
// @mui
import {
  Accordion,
  AccordionSummary,
  Button,
  Card,
  Container,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
// hooks and data
import moment from "moment";
// components
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MainDrawerMutationFiltersType } from "src/types/dashboardSectionsPropsTypes";
import {
  MainDrawerFiltersType,
  MainDrawerSendingFiltersType,
} from "src/types/main-filters-drawer-type";
import { useQueryParams } from "use-query-params";
import Iconify from "../../../components/iconify";
import Scrollbar from "../../../components/scrollbar";
// config
import { NAV } from "../../../config-global";
import EndDateFilter from "./MainFilterSections/EndDateFilter";
import FilterSectionButton from "./MainFilterSections/FilterSectionButton";
import MultiCheckboxFilter from "./MainFilterSections/MultiCheckboxFilter";
import StartDateFilter from "./MainFilterSections/StartDateFilter";

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onOpen: VoidFunction;
  onClose: VoidFunction;
  onResetFilter: VoidFunction;
  filters?: MainDrawerFiltersType;
  setParameters: Dispatch<SetStateAction<MainDrawerMutationFiltersType>>;
};

export default function MainFilterDrawer ({
  open,
  onOpen,
  onClose,
  onResetFilter,
  filters,
  setParameters,
}: Props) {
  // #region Form
  const { control, watch, setValue } = useFormContext<MainDrawerSendingFiltersType>();
  const [query, setQuery] = useQueryParams<any>({
    startDate: "",
    endDate: "",
  });
  // #endregion Form
  useEffect(() => {
    setValue("dateRangeFilter.start", watch().dateRangeFilter?.start);
    setValue("dateRangeFilter.end", watch().dateRangeFilter?.end);
  }, [filters?.dateRangeFilter, setValue, watch, query]);

  const handleApplyFilters = () => {
    if (setParameters)
      setParameters({
          languageFilter: watch().languageFilter || undefined,
          sentimentFilter: watch().sentimentFilter || undefined,
          keywordFilter: watch().keywordFilter || undefined,
          topicFilter: watch().topicFilter || undefined,
          dateRangeFilter: {
            start: moment(watch().dateRangeFilter?.start).format("DD-MM-YYYY"),
            end: moment(watch().dateRangeFilter?.end).format("DD-MM-YYYY"),
          },
      });
    setQuery({
      languageFilter: watch().languageFilter || null,
      sentimentFilter: watch().sentimentFilter || null,
      keywordFilter: watch().keywordFilter || null,
      topicFilter: watch().topicFilter || null,
      startDate: moment(watch().dateRangeFilter?.start).format("DD-MM-YYYY") || null,
      endDate: moment(watch().dateRangeFilter?.end).format("DD-MM-YYYY") || null,
    });
  };

  return (
    <>
      <Button
        disableRipple
        color='inherit'
        endIcon={<Iconify icon='ic:round-filter-list' />}
        onClick={onOpen}
      >
        Filters
      </Button>
      <Drawer
        anchor='right'
        open={open}
        onClose={onClose}
        BackdropProps={{
          invisible: true,
        }}
        PaperProps={{
          sx: { width: NAV.W_BASE },
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{ pl: 2, pr: 1, py: 2 }}
        >
          <Typography variant='subtitle1'>Filters</Typography>
          <IconButton onClick={onClose}>
            <Iconify icon='eva:close-fill' />
          </IconButton>
        </Stack>
        <Divider />
        <Scrollbar>
          <Stack spacing={3} sx={{ p: 2.5 }}>
            <FilterContainer label='Date filter'>
              <Stack spacing={1.5} pb={3}>
                <StartDateFilter control={control} dashboardFilter={filters} />
                <EndDateFilter control={control} dashboardFilter={filters} />
              </Stack>
            </FilterContainer>
            {filters?.topicFilter?.length && (
              <FilterContainer label='Topic filter'>
                <MultiCheckboxFilter
                  options={filters?.topicFilter || []}
                  name='topicFilter'
                  label='Topics Filter'
                />
              </FilterContainer>
            )}
            {filters?.languageFilter?.length && (
              <FilterContainer label='Language filter'>
                <MultiCheckboxFilter
                  options={filters?.languageFilter || []}
                  name='languageFilter'
                />
              </FilterContainer>
            )}
            {filters?.keywordFilter?.length && (
              <FilterContainer label='Keyword filter'>
                <MultiCheckboxFilter
                  options={filters?.keywordFilter || []}
                  name='keywordFilter'
                  label='Keywords Filter'
                />
              </FilterContainer>
            )}
            {filters?.sentimentFilter?.length && (
              <FilterContainer label='Sentiment filter'>
                <MultiCheckboxFilter
                  options={filters?.sentimentFilter || []}
                  name='sentimentFilter'
                  label='Sentiment Filter'
                />
              </FilterContainer>
            )}
          </Stack>
        </Scrollbar>
        <FilterSectionButton
          onClick={handleApplyFilters}
          label='Apply Filters'
          icon='eva:checkmark-circle-outline'
        />
        <FilterSectionButton
          onClick={() => {
            onResetFilter();
          }}
          label='Reset Filters'
          icon='eva:trash-2-outline'
        />
      </Drawer>
    </>
  );
}

const FilterContainer = ({ children, label }: { children: any; label: string }) => {
  return (
    <Card>
      <Accordion
        sx={{
          borderRadius: 2,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel2a-content'
          id='panel2a-header'
        >
          <Typography variant='caption'>{label}</Typography>
        </AccordionSummary>
        <Container>{children}</Container>
      </Accordion>
    </Card>
  );
};
