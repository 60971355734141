import { PATH_AUTH } from 'src/routes/paths';
import { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Box, Stack, Drawer, Button } from '@mui/material';
import Image from 'src/components/image/Image';
// hooks
import { useSnackbar } from 'notistack';
import { useAuthContext } from 'src/auth/useAuthContext';
import useResponsive from '../../../hooks/useResponsive';
// images
import logo from '../../../assets/flower-logo.svg';
// config
import { NAV } from '../../../config-global';
// components
import Scrollbar from '../../../components/scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
//
import navConfig from './config-navigation';
import NavToggleButton from './NavToggleButton';
// ----------------------------------------------------------------------

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
  navElements?: any
};

export default function NavVertical({ openNav, onCloseNav, navElements }: Props) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { logout } = useAuthContext();
  const handleLogout = async () => {
    try {
      logout();
      navigate(PATH_AUTH.login, { replace: true });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };
  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
        }}
      >
        <Link to='/'>
          <Image src={logo} sx={{ width: '50%' }} />
        </Link>
      </Stack>
      <NavSectionVertical data={navElements || navConfig} />
      <Box sx={{ flexGrow: 1 }} />
        <Button variant="outlined" color="primary" onClick={handleLogout} sx={{ px: 2, width: '80%', margin: '10px auto' }}>
          Logout
        </Button>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD },
      }}
    >
      <NavToggleButton />

      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              zIndex: 0,
              width: NAV.W_DASHBOARD,
              bgcolor: 'transparent',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
