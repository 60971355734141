import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller } from "react-hook-form";

type endDateFilterPropsType = {
  control: any;
  dashboardFilter: any;
};

const EndDateFilter = ({ control, dashboardFilter }: endDateFilterPropsType) => {
  console.log(dashboardFilter)
  return (
    <Controller
      name='dateRangeFilter.end'
      control={control}
      render={({ field }) => {
        return (
          <DatePicker
            label='End date'
            inputFormat='dd-MM-yyyy'
            value={field.value || dashboardFilter?.dateRangeFilter?.end}
            onChange={field.onChange}
            renderInput={(params: any) => <TextField {...params} />}
            minDate={new Date(dashboardFilter?.dateRangeFilter?.start)}
            maxDate={new Date(dashboardFilter?.dateRangeFilter?.end)}
          />
        );
      }}
    />
  );
};

export default EndDateFilter;
