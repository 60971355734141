import { Navigate, useRoutes } from "react-router-dom";
import App from "src/pages/landingPage/LandingPage";
// Custom dashboards
import CustomDashboard from "src/pages/dashboard/custom-dashboard/CustomDashboard";
// errors pages
import CompactLayout from "src/layouts/compact/CompactLayout";
import ComingSoonPage from "src/pages/ComingSoonPage";
import Page403 from "src/pages/Page403";
import Page404 from "src/pages/Page404";
import Page500 from "src/pages/Page500";
// auth
import AuthGuard from "src/auth/AuthGuard";
import CustomDashboardLayout from "src/layouts/dashboard/adam-custom-dashboard/CustomDashboardLayout";
import MediumDashboard from "src/pages/dashboard/platforms-main-dashboards/newspaper-platforms/medium/MediumDashboard";
import YoutubeDashboard from "src/pages/dashboard/platforms-main-dashboards/Social-media-platforms/youtube/YoutubeDashboard";
import GuestGuard from "../auth/GuestGuard";
import DashboardLayout from "../layouts/dashboard";
// config
//
import {
  LoginPage,
  // Auth
  RegisterPage
} from "./elements";
import ForbesDashboard from "src/pages/dashboard/platforms-main-dashboards/newspaper-platforms/forbes/ForbesDashboard";
import QuartzDashboard from "src/pages/dashboard/platforms-main-dashboards/newspaper-platforms/quartz/QuartzDashboard";
import NYCTimesDashboard from "src/pages/dashboard/platforms-main-dashboards/newspaper-platforms/nyc-times/NYCTimesDashboard";
import MercuryDashboard from "src/pages/dashboard/platforms-main-dashboards/newspaper-platforms/mercury-news/MercuryNewsDashboard";

// ----------------------------------------------------------------------

export default function Router () {
  return useRoutes([
    {
      path: "/",
      element: <App />,
    },
    // Auth
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
      ],
    },

    // Dashboard
    { path: "dashboard/main/reddit", element: <Navigate to='/coming-soon' replace /> },
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "youtube",
          element: <YoutubeDashboard />,
        },
        {
          path: "medium",
          element: <MediumDashboard />,
        },
        {
          path: "forbes",
          element: <ForbesDashboard />,
        },
        {
          path: "quartz",
          element: <QuartzDashboard />,
        },
        {
          path: "nyc-times",
          element: <NYCTimesDashboard />,
        },
        {
          path: "mercury-news",
          element: <MercuryDashboard />,
        },
      ],
    },
    {
      path: "radar",
      element: (
        <AuthGuard>
          <CustomDashboardLayout>
            <CustomDashboard />
          </CustomDashboardLayout>
        </AuthGuard>
      ),
    },
    {
      element: <CompactLayout />,
      children: [
        { path: "coming-soon", element: <ComingSoonPage /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
      ],
    },

    { path: "*", element: <Navigate to='/404' replace /> },
  ]);
}
