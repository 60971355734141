import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import ImagesShow from './ImagesShow';

const Hero = (): any => {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ImagesShow />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 2,
          gap: '40px',
        }}
      >
        <Typography variant="h2" sx={{ mb: 2 }} color="#ffffff" textAlign='center'>
          Get Insights on Islamic Topics with{' '}
          <Typography variant="h2" color="secondary" sx={{ display: 'inline' }} textAlign='center'>
            Adam Radar
          </Typography>
        </Typography>
        <Typography
          variant="body1"
          sx={{ mb: 2, fontSize: { xs: '24px', md: '36px' }, fontFamily: 'inter' }}
          color="#ffffff"
          textAlign='center'
        >
          Adam Radar is the ultimate monitoring tool for all things related to Islam. With our
          comprehensive data scraping technology, you can stay informed and up-to-date on the latest
          discussions and developments in the Islamic world.
        </Typography>
        <Button color="info" variant="contained" size="large" sx={{ mb: 2 }} onClick={()=>navigate('/radar')}>
          Access Adam Radar Now
        </Button>
        <Typography
          variant="caption"
          position="absolute"
          bottom={3}
          sx={{ textAlign: 'center' }}
          color="#ffffff"
          width='80%'
        >
          Copyright © Iadam Radar Analytics Private Limited 2018-2023. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Hero;
