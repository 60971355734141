import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
// slices
import adamData from './slices/adamData'
import dataSetSlice from './slices/dataSetSlice'

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
}

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
}

const rootReducer = combineReducers({
  adamRadar: adamData,
  dataset: dataSetSlice
})

export default rootReducer
