import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import image from '../../assets/adam-radar-mobile.svg';

const AdamRadar = () => {
  const navigate = useNavigate()
  const titleStyle = {
    fontWeight: '600',
    fontSize: '60px',
    mb: 2,
  };
  const subTitleStyle = {
    fontWeight: '400',
    fontSize: '32px',
    mb: 3,
  };
  return (
    <Box
      sx={{
        background:
          'linear-gradient(89.12deg, #40EBD4 -2.77%, #4AD5D7 6.09%, #7477E5 34.25%, #7F5FE9 102.15%)',
        padding: { xs: 4, sm: 6, md: 8 },
        color: 'common.white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mb: { xs: 4, md: 0 },
          mr: { xs: 0, md: 6 },
        }}
      >
        <img src={image} alt="Adam Radar" />
      </Box>
      <Box textAlign={{ xs: 'center', md: 'left' }} display='flex' flexDirection='column' gap='30px'>
        <Typography fontWeight={600} fontFamily="poppins" variant="mainSectionTitle" color='white' sx={{ titleStyle }}>
          Adam Radar
        </Typography>
        <Typography
          fontWeight={400}
          fontFamily="poppins"
          variant="subtitle1"
          sx={{ subTitleStyle }}
        >
          Search and analyze any Islamic keyword in a glance
        </Typography>
        <Button
          
          size="medium"
          variant="contained"
          sx={{ px: '60px', width:'50%' }}
          onClick={()=>navigate('/radar')}
        >
          Try now
        </Button>
      </Box>
    </Box>
  );
};

export default AdamRadar;
