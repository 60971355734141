import axiosInstance from './axios';

export const getCustomData = async (values: any) => {
  const requestBody = values.applyFilters
    ? {
        dataset: values?.dataset,
        filters: {
          topicFilter: values?.inputs.customTopicFilter.length
            ? values?.inputs.customTopicFilter
            : undefined,
          sentimentFilter: values?.inputs.customSentimentFilter.length
            ? values?.inputs.customSentimentFilter
            : undefined,
          languageFilter: values?.inputs.customLanguageFilter.length
            ? values?.inputs.customLanguageFilter
            : undefined,
        },
      }
    : {
        startDate: values?.inputs?.startDate,
        endDate: values?.inputs?.endDate,
        keyword: values?.inputs?.keyword,
        source: 'custom',
      };
  const res = await axiosInstance.post(values.platform, requestBody);
  console.log(res);
  return res.data;
};
