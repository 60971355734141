// scroll bar
import "simplebar-react/dist/simplebar.min.css";

// lightbox
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/styles.css";

// map
import "mapbox-gl/dist/mapbox-gl.css";
import "./utils/mapboxgl";

// editor
import "react-quill/dist/quill.snow.css";

// slick-carousel
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";

// ----------------------------------------------------------------------

import { HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/lib/integration/react";
// @mui
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// react query
import {
  QueryClient as NewQueryClient,
  QueryClientProvider as NewQueryClientProvider,
} from "@tanstack/react-query";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
// Url params
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import queryString from "query-string";
// redux
import { SnackbarProvider } from "src/components/snackbar";
import { persistor, store } from "./redux/store";
// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// locales
import ThemeLocalization from "./locales";
// components
import { MotionLazyContainer } from "./components/animate";
import { StyledChart } from "./components/chart";
import ScrollToTop from "./components/scroll-to-top";
import { SettingsProvider, ThemeSettings } from "./components/settings";
import { AuthProvider } from "./auth/JwtContext";
// ----------------------------------------------------------------------

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const newQueryClient = new NewQueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export default function App () {
  return (
    <BrowserRouter>
      <AuthProvider>
        <NewQueryClientProvider client={newQueryClient}>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider>
              <HelmetProvider>
                <ReduxProvider store={store}>
                  <PersistGate loading={null} persistor={persistor}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <SettingsProvider>
                        <QueryParamProvider
                          adapter={ReactRouter6Adapter}
                          options={{
                            searchStringToObject: queryString.parse,
                            objectToSearchString: queryString.stringify,
                          }}
                        >
                          <ScrollToTop />
                          <MotionLazyContainer>
                            <ThemeProvider>
                              <ThemeSettings>
                                <ThemeLocalization>
                                  <SnackbarProvider>
                                    <StyledChart />
                                    <ReactQueryDevtools
                                      position='bottom-left'
                                      initialIsOpen={false}
                                    />
                                    <Router />
                                  </SnackbarProvider>
                                </ThemeLocalization>
                              </ThemeSettings>
                            </ThemeProvider>
                          </MotionLazyContainer>
                        </QueryParamProvider>
                      </SettingsProvider>
                    </LocalizationProvider>
                  </PersistGate>
                </ReduxProvider>
              </HelmetProvider>
            </ThemeProvider>
          </QueryClientProvider>
        </NewQueryClientProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}
