import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen showImage />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// RegisterPage,
// LoginPage,
// GeneralAppPage,
// Page500,
// Page403,
// Page404,
// ComingSoonPage,
// MaintenancePage,

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
