import { FC } from 'react';
import Footer from 'src/layouts/main/Footer';
import Header from 'src/layouts/main/Header';
import SocialMedia from 'src/sections/@landingPage/SocialMedia';
import '../../Styles/style.css';
import AutomatedDB from 'src/sections/@landingPage/AutomatedDB';
import WorkingProgress from 'src/sections/@landingPage/WorkingProgress';
import KeywordsAnalytics from 'src/sections/@landingPage/KeywordsAnalytics';
import AdamRadar from 'src/sections/@landingPage/AdamRadar';
import HowToUse from 'src/sections/@landingPage/HowToUse';
import Hero from '../../sections/@landingPage/Hero/Hero';

const LandingPage: FC = () => {
  return (
    <div>
      <Header />
      <Hero />
      <SocialMedia />
      <AutomatedDB />
      <WorkingProgress />
      <KeywordsAnalytics />
      <AdamRadar />
      <HowToUse />
      <Footer />
    </div>
  );
};

export default LandingPage;
