import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import DataCardViewer from 'src/components/DataCardViewer';
import Iconify from 'src/components/iconify/Iconify';
import { DashboardMostViewedQPropsType } from 'src/types/dashboardSectionsPropsTypes';

const ExpanderView = ({ data, dataArr, index, videoPreviewUrl }: DashboardMostViewedQPropsType) => {
  let itemWidth;
  const remainder = (dataArr?.length || 0) % 3;
  if (remainder === 2) {
    itemWidth = (dataArr?.length || 0) - index > 2 ? 4 : 6;
  } else if (remainder === 1) {
    itemWidth = (dataArr?.length || 0) - index > 1 ? 4 : 12;
  } else {
    itemWidth = 4; // Default width for other lengths
  }

  return (
    <Grid item xs={12} sm={itemWidth} key={index}>
      <Stack spacing={3}>
        <Card>
          <Accordion>
            <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="subtitle1">{data?.title}</Typography>
                {videoPreviewUrl && (
                  <iframe
                    src="https://www.youtube.com/embed/tgbNymZ7vqY"
                    title="preview"
                    style={{
                      borderRadius: '10px',
                      border: 'none',
                    }}
                  />
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <DataCardViewer data={data?.value! || {}} />
            </AccordionDetails>
          </Accordion>
        </Card>
      </Stack>
    </Grid>
  );
};

export default ExpanderView;
