import { Box, Container, Stack, Typography } from '@mui/material';

import styled from '@emotion/styled';
import { pxToRem } from 'src/theme/typography';
import image from '../../assets/landing-page/how-to-use.png';

const HowToUse = () => {
  const ParNumber = styled(Typography)({
    fontWeight: 700,
    fontSize: pxToRem(48),
    color: '#5db1ba',
    marginBottom: pxToRem(10),
  });
  const paragraphs = [
    {
      title: 'Sign Up for an account',
      description: "It's easy to get started. Simply sign up for a free account on our website.",
    },
    {
      title: 'Choose your platforms',
      description:
        'Select the platforms you want to monitor, from social media to discussion forums, and start tracking keywords and hashtags related to Islam.',
    },
    {
      title: 'View your Dashboard',
      description:
        'Our interactive dashboard provides a clear view of the scraped data, allowing you to easily analyze and understand the insights.',
    },
    {
      title: 'Export Data',
      description:
        'You can export the scraped data as an Excel file or CSV for further analysis or documentation.',
    },
  ].map((par, index) => (
    <Box
      key={index}
      sx={{
        display: 'flex',
        gap: 2,
        alignItems: { xs: 'center', md: 'flex-start' },
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      <ParNumber>{`0${index + 1}`}</ParNumber>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: { xs: 'center', md: 'flex-start' },
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        <Typography variant="parTitle">{par.title}</Typography>
        <Typography variant="parDescription" width="75%">
          {par.description}
        </Typography>
      </Box>
    </Box>
  ));
  return (
    <Box sx={{ py: { lg: 8, sm: 16 }, marginX: { xs: 0, sm: 4, md: 6, lg: 10 } }}>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            gap: { xs: 2, md: 4 },
          }}
        >
          <Box sx={{ width: { xs: '100%', md: '60%' } }} textAlign={{ xs: 'center', md: 'left' }}>
            <Typography variant="smallSectionTitle" gutterBottom>
              How to use
            </Typography>
            <Stack>
              <Typography variant="mainSectionTitle" gutterBottom>
                Adam Radar
              </Typography>
            </Stack>
            <Box display='flex' flexDirection='column' gap={6}>{paragraphs}</Box>
          </Box>
          <Box sx={{ width: { xs: '100%', md: '40%' } }}>
            <img src={image} alt="how-to-use" />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default HowToUse;
