// routes
import Iconify from "src/components/iconify/Iconify";
import { PATH_DASHBOARD } from "../../../routes/paths";

// ----------------------------------------------------------------------

const icon = (name: string) => <Iconify icon={name} />;

const ICONS = {
  home: icon("eva:home-outline"),
  platforms: icon("eva:message-circle-outline"),
  newspaper: icon("eva:book-open-outline"),
  trends: icon("eva:radio-button-on-outline"),
  logout: icon("eva:log-out-outline"),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "",
    items: [
      { title: "radar", path: "/radar", icon: ICONS.home },
      {
        title: "social media",
        path: "dashboard",
        icon: ICONS.home,
        children: [
          // { title: 'twitter', path: PATH_DASHBOARD.socialMedia.twitter },
          { title: "youtube", path: PATH_DASHBOARD.socialMedia.youtube },
        ],
      },
      {
        title: "Newspaper",
        path: "dashboard",
        icon: ICONS.home,
        children: [
          // { title: 'twitter', path: PATH_DASHBOARD.socialMedia.twitter },
          { title: "medium", path: PATH_DASHBOARD.socialMedia.medium },
          { title: "forbes", path: PATH_DASHBOARD.socialMedia.forbes },
          { title: "quartz", path: PATH_DASHBOARD.socialMedia.quartz },
          { title: "NYC Times", path: PATH_DASHBOARD.socialMedia.nycTimes },
          { title: "Mercury news", path: PATH_DASHBOARD.socialMedia.mercuryNews },
        ],
      },
    ],
  },
];
export default navConfig;
