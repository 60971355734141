import { useState } from 'react';
import FormProvider from 'src/components/hook-form/FormProvider';
import { Box, Container, Tab, Tabs } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useSettingsContext } from 'src/components/settings';
import { Outlet } from 'react-router';
import { customFiltersConfig } from 'src/layouts/dashboard/adam-custom-dashboard/custom-filters-config';
import { customDashboardInputType } from 'src/types/dashboardData';
import Iconify from 'src/components/iconify/Iconify';
import { customDashboardTabs } from 'src/layouts/dashboard/adam-custom-dashboard/custom-dashboard-tabs';
import { getCustomData } from 'src/utils/getCustomData';
import { useQuery } from 'react-query';
import SearchBar from '../../../sections/custom-dashboard/SearchBar';
import YoutubeCustomDashboard from '../platforms-custom-dashboard/social-media-dashboards-custom/YoutubeCustomDashboard';
// import IslamQACustomDashboard from '../platforms-custom-dashboard/islamic-dashboards-custom/IslamQACustomDashboard';
// import BinbazCustomDashboard from '../platforms-custom-dashboard/islamic-dashboards-custom/BinbazCustomDashboard';
// import IslamWebCustomDashboard from '../platforms-custom-dashboard/islamic-dashboards-custom/IslamWebCustomDashboard';
// import TwitterCustomDashboard from '../platforms-custom-dashboard/social-media-dashboards-custom/TwitterCustomDashboard';
// import GoogleTrendsCustomDashboard from '../platforms-custom-dashboard/social-media-dashboards-custom/GoogleTrendsCustomDashboard';
// import ForbesCustomDashboard from '../platforms-custom-dashboard/newspaper-dashboards-custom/ForbesCustomDashboard';
// import MediumCustomDashboard from '../platforms-custom-dashboard/newspaper-dashboards-custom/MediumCustomDashboard';
// import QuartzCustomDashboard from '../platforms-custom-dashboard/newspaper-dashboards-custom/QuartzCustomDashboard';
// import NYCCustomDashboard from '../platforms-custom-dashboard/newspaper-dashboards-custom/NYCCustomDashboard';
// import MercuryCustomDashboard from '../platforms-custom-dashboard/newspaper-dashboards-custom/MercuryCustomDashboard';
// ----------------------------------------------------------------------

export default function CustomDashboard(): any {
  const defaultValues: customDashboardInputType = customFiltersConfig.radar;
  const methods = useForm<customDashboardInputType>({
    defaultValues,
  });
  // #region States
  const selectedPlatforms = [
    ...(methods?.watch()?.discussionPlatforms || []),
    ...(methods?.watch()?.newspaper || []),
    ...(methods?.watch()?.socialMedia || []),
    ...(methods?.watch()?.islamicWebsites || []),
  ];

  const [currentTab, setCurrentTab] = useState('');
  const tabsFiltered = customDashboardTabs.filter((tab) =>
    selectedPlatforms.map((platform) => platform.toLowerCase()).includes(tab.value.toLowerCase())
  );

  const resetFilters = {
    ...methods.watch(),
    customTopicFilter: [],
    customSentimentFilter: [],
    customLanguageFilter: [],
  };

  const { refetch } = useQuery(
    `${currentTab}Custom`,
    () =>
      getCustomData({
        inputs: methods.watch(),
        platform: currentTab,
      }),
    { refetchOnMount: false, enabled: false }
  );

  const TABS = [
    // {
    //   label: 'Binbaz',
    //   value: 'binbaz',
    //   component: <BinbazCustomDashboard />,
    // },
    // {
    //   label: 'Islam QA',
    //   value: 'islamqa',
    //   component: <IslamQACustomDashboard />,
    // },
    // {
    //   label: 'Islam web',
    //   value: 'islamweb',
    //   component: <IslamWebCustomDashboard />,
    // },
    // {
    //   label: 'Twitter',
    //   value: 'twitter',
    //   component: <TwitterCustomDashboard />,
    // },
    {
      label: 'Youtube',
      value: 'youtube',
      component: <YoutubeCustomDashboard />,
    },
    // {
    //   label: 'Google trends',
    //   value: 'googletrends',
    //   component: <GoogleTrendsCustomDashboard />,
    // },
    // {
    //   label: 'Forbes',
    //   value: 'forbes',
    //   component: <ForbesCustomDashboard />,
    // },
    // {
    //   label: 'Medium',
    //   value: 'medium',
    //   component: <MediumCustomDashboard />,
    // },
    // {
    //   label: 'The Mercury News',
    //   value: 'mercurynews',
    //   component: <MercuryCustomDashboard />,
    // },
    // {
    //   label: 'Quartz',
    //   value: 'quartz',
    //   component: <QuartzCustomDashboard />,
    // },
    // {
    //   label: 'NYC Times',
    //   value: 'nyctimes',
    //   component: <NYCCustomDashboard />,
    // },
    {
      value: '',
      component: ''
    }
  ].filter(tab => customDashboardTabs.map(tabConfig => tabConfig.value).includes(tab.value));
  // #region States

  const themeStretch = useSettingsContext();
  // #region Form

  // #endregion Form

  return (
    <FormProvider methods={methods}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Helmet>
          <title>Adam Radar</title>
        </Helmet>
        <SearchBar currentTab={currentTab} refetch={refetch} setCurrentTab={setCurrentTab} />
        <Tabs
          value={currentTab}
          onChange={(event, newValue) => {
            setCurrentTab(newValue);
            methods.reset(resetFilters);
          }}
        >
          {tabsFiltered.map((tab: any) => (
            <Tab
              key={tab.value}
              label={tab.label}
              icon={<Iconify icon={tab.icon} />}
              value={tab.value}
            />
          ))}
        </Tabs>

        {TABS.map(
          (tab) =>
            tab.value === currentTab && (
              <Box key={tab.value} sx={{ mt: 5 }}>
                {tab.component}
              </Box>
            )
        )}
        <Outlet />
      </Container>
    </FormProvider>
  );
}
