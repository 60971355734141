// @mui
import { Typography, Stack, Container } from '@mui/material';
// components
import Image from '../../components/image';
import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from './styles';
import logo from '../../assets/flower-logo.svg';
// ----------------------------------------------------------------------

type Props = {
  title?: string;
  illustration?: string;
  children: React.ReactNode;
};

export default function LoginLayout({ children, illustration, title }: Props) {
  return (
    <StyledRoot>
      <StyledSection sx={{ backgroundColor: '#0B1D60' }}>
        <Stack spacing={8} sx={{ alignItems: 'center' }}>
          <Image
            disabledEffect
            visibleByDefault
            alt="auth"
            src={illustration || '/assets/illustrations/illustration_dashboard.png'}
          />
          <Typography
            fontFamily="spectral"
            variant="h3"
            sx={{ mb: 10, maxWidth: 480, textAlign: 'center' }}
          >
            {title}
          </Typography>
        </Stack>
        <StyledSectionBg />
      </StyledSection>
      <StyledContent
        sx={{
          width: { xs: '100%', md: '50%' },
          padding: '0px  !important',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <Stack width={0.5}>
          <Container
            sx={{ position: 'absolute', top: '0px', left: '0px', width: '20%', padding: '15px' }}
          >
            <Image src={logo} />
          </Container>
          {children}
        </Stack>
      </StyledContent>
    </StyledRoot>
  );
}
