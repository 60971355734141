import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Controller } from 'react-hook-form';

type MultiSelectDropDownPropsType = {
  control: any;
  data: {
    label: string;
    value: string;
  }[];
  name: string;
  label: string;
};

const MultiSelectDropDown = ({ control, data, name, label }: MultiSelectDropDownPropsType) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl fullWidth>
          <InputLabel id="Select">{label}</InputLabel>
          <Select labelId="Select" fullWidth multiple {...field} label="Grouping">
            {data?.map((platform: { label: string; value: string }) => {
              return (
                <MenuItem key={platform.label} value={platform.value}>
                  {platform.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default MultiSelectDropDown;
