import { YoutubeCustomDtoType } from "src/types/custom-dashboards/youtube";
import { ForbesMainDtoType } from "src/types/main-dashboards/newspaper/forbes";
import { MediumMainDtoType } from "src/types/main-dashboards/newspaper/medium";
import { MercuryNewsDtoType } from "src/types/main-dashboards/newspaper/mercury-news";
import { NYCTimesDtoType } from "src/types/main-dashboards/newspaper/nyc-times";
import { QuartzMainDtoType } from "src/types/main-dashboards/newspaper/quartz";
import { YoutubeMainDtoType } from "src/types/main-dashboards/social-media/youtube";
import { PlatformsDtoType } from "src/types/platforms";

enum URL_PATH {
  MAIN = "main-dashboard",
  CUSTOM = "custom-dashboard",
}

export const API_URLS = {
  // Platforms
  PLATFORMS: "platforms",
  //   Youtube
  YOUTUBE_MAIN: `analysis/${URL_PATH.MAIN}/youtube`,
  YOUTUBE_CUSTOM: `analysis/${URL_PATH.CUSTOM}/youtube`,
  YOUTUBE_MAIN_FILTERS: `filters/${URL_PATH.MAIN}/youtube`,
  YOUTUBE_MAIN_CSV: `csv/${URL_PATH.MAIN}/youtube`,
  // Medium
  MEDIUM_MAIN: `analysis/${URL_PATH.MAIN}/medium`,
  MEDIUM_MAIN_FILTERS: `filters/${URL_PATH.MAIN}/medium`,
  MEDIUM_MAIN_CSV: `csv/${URL_PATH.MAIN}/medium`,
  // Forbes
  FORBES_MAIN: `analysis/${URL_PATH.MAIN}/forbes`,
  FORBES_MAIN_FILTERS: `filters/${URL_PATH.MAIN}/forbes`,
  FORBES_MAIN_CSV: `csv/${URL_PATH.MAIN}/forbes`,
  // Quartz
  QUARTZ_MAIN: `analysis/${URL_PATH.MAIN}/quartz`,
  QUARTZ_MAIN_FILTERS: `filters/${URL_PATH.MAIN}/quartz`,
  QUARTZ_MAIN_CSV: `csv/${URL_PATH.MAIN}/quartz`,
  // NYCTimes
  NYC_TIMES_MAIN: `analysis/${URL_PATH.MAIN}/nyc-times`,
  NYC_TIMES_MAIN_FILTERS: `filters/${URL_PATH.MAIN}/nyc-times`,
  NYC_TIMES_MAIN_CSV: `csv/${URL_PATH.MAIN}/nyc-times`,
  // Mercury news
  MERCURY_NEWS_MAIN: `analysis/${URL_PATH.MAIN}/mercury-news`,
  MERCURY_NEWS_MAIN_FILTERS: `filters/${URL_PATH.MAIN}/mercury-news`,
  MERCURY_NEWS_MAIN_CSV: `csv/${URL_PATH.MAIN}/mercury-news`,
};

export type ResDataType = {
  platforms: PlatformsDtoType;
  //   Youtube
  youtubeMain: YoutubeMainDtoType;
  youtubeCustom: YoutubeCustomDtoType;
  // Medium
  mediumMain: MediumMainDtoType;
  // Quartz
  quartzMain: QuartzMainDtoType;
  // Forbes
  forbesMain: ForbesMainDtoType;
  // NYC times
  nycTimes: NYCTimesDtoType;
  // Mercury news
  mercuryNews: MercuryNewsDtoType;
};
