import { useRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
// utils
// components
import Image from 'src/components/image';
import Carousel, { CarouselArrows } from 'src/components/carousel';
// ----------------------------------------------------------------------
type Props = {
  data: {
    id: string;
    image: string;
    title: string;
    width?: string | number;
  }[];
};
export default function CarouselCenterMode({ data }: Props) {
  const carouselRef = useRef<Carousel | null>(null);
  const theme = useTheme();
  const carouselSettings = {
    slidesToShow: 3,
    rtl: Boolean(theme.direction === 'rtl'),
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 1, centerPadding: '0' },
      },
    ],
  };
  const handlePrev = () => {
    carouselRef.current?.slickPrev();
  };
  const handleNext = () => {
    carouselRef.current?.slickNext();
  };
  return (
    <Box
      sx={{
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <CarouselArrows
        filled
        icon="gridicons:arrow-right"
        onNext={handleNext}
        onPrevious={handlePrev}
      >
        <Carousel ref={carouselRef} {...carouselSettings}>
          {data.map((item) => (
            <Box key={item.id} sx={{ px: 10, py: 2 }}>
              <CarouselItem item={item} />
            </Box>
          ))}
        </Carousel>
      </CarouselArrows>
    </Box>
  );
}
// ----------------------------------------------------------------------
type CarouselItemProps = {
  title: string;
  image: string;
  width?: string | number;
};
function CarouselItem({ item }: { item: CarouselItemProps }) {
  const { image, title, width } = item;
  return (
    <Image
      className="image-contain"
      sx={{
        width: width || 'auto',
        height: 'auto',
        margin: 'auto',
      }}
      alt={title}
      src={image}
      ratio="16/9"
    />
  );
}
