import { Box, Grid, Typography } from '@mui/material';

import img1 from '../../assets/keywords/img1.svg';
import img2 from '../../assets/keywords/img2.svg';
import img3 from '../../assets/keywords/img3.svg';
import img4 from '../../assets/keywords/img4.svg';
import img5 from '../../assets/keywords/img5.svg';

const KeywordsAnalytics = () => {
  const paragraphs = [
    {
      img: img1,
      title: 'Monitor specific keywords related to Islam',
      description:
        'This process helps to keep track of the public perception and understanding of Islam.',
    },
    {
      img: img2,
      title: 'Export to Excel or CSV',
      description:
        'Export to Excel or CSV (Comma Separated Values) is a feature that allows users to download and save data from a software application or database to a file format',
    },
    {
      img: img3,
      title:
        'Stay informed on the latest discussions and developments in the Islamic world in real-time.',
      description:
        'Staying informed on the latest discussions and developments in the Islamic world in real-time involves regularly monitoring a variety of sources, such as news websites, social media platforms, and online forums, that cover topics related to Islam and Muslim communities around the world.',
    },
    {
      img: img4,
      title: 'Monitor Islamic topics across multiple platforms',
      description:
        'This approach allows users to gain a comprehensive understanding of how different communities and individuals are discussing and engaging with topics related to Islam, as well as to identify emerging trends and issues.',
    },
    {
      img: img5,
      title: 'Access to clear and concise data',
      description:
        'Access to clear and concise data involves having easy and straightforward access to information that is presented in a simple and understandable way.',
    },
  ].map((e) => (
    <Grid item xs={12} lg={6} direction="row" p={4}>
      <Box
        display="flex"
        sx={{
          display: 'flex',
          gap: 2,
          alignItems: { xs: 'center', lg: 'flex-start' },
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        <Box sx={{ mr: 2 }}>
          <img src={e.img} alt="" />
        </Box>
        <Box sx={{ flex: 1, textAlign: { xs: 'center', lg: 'left' } }}>
          <Typography variant="parTitle" sx={{ mb: 1 }}>
            {e.title}
          </Typography>
          <br />
          <Typography variant="parDescription">{e.description}</Typography>
        </Box>
      </Box>
    </Grid>
  ));

  return (
    <Box
      sx={{
        py: { lg: 8, sm: 16 },
        marginX: { xs: 0, sm: 4, md: 6, lg: 10 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      gap={10}
    >
      <Typography variant="mainSectionTitle" sx={{ mb: 4, textAlign: { xs: 'center' } }}>
        Keywords Analytics Features
      </Typography>
      <Grid container>{paragraphs}</Grid>
    </Box>
  );
};

export default KeywordsAnalytics;
