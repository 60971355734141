import { yupResolver } from "@hookform/resolvers/yup";
import { Container, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import FormProvider from "src/components/hook-form/FormProvider";
import { useSettingsContext } from "src/components/settings";
import { MercuryNewsMainFiltersSchema } from "src/schemas";
import DashboardBarChart from "src/sections/adam-main-dashboard/DashboardBarChart";
import DashboardCards from "src/sections/adam-main-dashboard/DashboardCards";
import DashboardLineChart from "src/sections/adam-main-dashboard/DashboardLineChart";
import DashboardPieChart from "src/sections/adam-main-dashboard/DashboardPieChart";
import DashboardTopWords from "src/sections/adam-main-dashboard/DashboardTopWords";
import DashboardWordCloud from "src/sections/adam-main-dashboard/DashboardWordCloud";
import { MercuryNewsMainFiltersType } from "src/types/main-dashboards/newspaper/mercury-news";
import { useQueryParams } from "use-query-params";
import MercuryNewsMainDashboardHeader from "./MercuryNewsMainDashboardHeader";

const MercuryDashboard = () => {
  // #region States and hooks
  const { useGetListApi, useDownloadCsv } = useApiServices();
  const { themeStretch } = useSettingsContext();
  const theme = useTheme();
  // Get filters default from url params if available
  const [query] = useQueryParams({
    keywordFilter: "",
    sentimentFilter: "",
    languageFilter: "",
    topicFilter: "",
    startDate: "",
    endDate: "",
  });
  const [parameters, setParameters] = useState<MercuryNewsMainFiltersType>({
    keywordFilter: query.keywordFilter?.split(","),
    sentimentFilter: query.sentimentFilter?.split(","),
    languageFilter: query.languageFilter?.split(","),
    dateRangeFilter: {
      start: query.startDate!,
      end: query.endDate!,
    },
  });
  // #endregion States and hooks

  // #region Filters form
  const filters = useForm<MercuryNewsMainFiltersType>({
    defaultValues: {
      keywordFilter: query.keywordFilter?.split(","),
      sentimentFilter: query.sentimentFilter?.split(","),
      languageFilter: query.languageFilter?.split(","),
      dateRangeFilter: {
        start: query.startDate!,
        end: query.endDate!,
      },
    },
    resolver: yupResolver(MercuryNewsMainFiltersSchema),
  });
  // #endregion Filter form

  // #region Services
  // Get charts data and analysis
  const { data: platformData, isLoading: isDataLoading } = useGetListApi<
    ResDataType["mercuryNews"]
  >({
    url: API_URLS.MERCURY_NEWS_MAIN,
    parameters: {
      filters: JSON.stringify(parameters),
    },
  });

  // Get this platform accepted filters and enums
  const { data: filtersData, isLoading: isLoadingOptions } = useGetListApi<{
    dashboardFilter: MercuryNewsMainFiltersType;
  }>({
    url: API_URLS.MERCURY_NEWS_MAIN_FILTERS,
    enabled: !!platformData,
    onSuccess: (data: { dashboardFilter: MercuryNewsMainFiltersType }) => {
      if (!filters.watch().dateRangeFilter?.start) {
        filters.setValue("dateRangeFilter.start", data.dashboardFilter.dateRangeFilter?.start);
      }
      if (!filters.watch().dateRangeFilter?.end) {
        filters.setValue("dateRangeFilter.end", data.dashboardFilter.dateRangeFilter?.end);
      }
    },
  });

  // Download data CSV report
  const { mutate: downloadCsv, isLoading } = useDownloadCsv({ url: API_URLS.MERCURY_NEWS_MAIN_CSV });

  const handleDownloadCsv = () => {
    downloadCsv({ filters: filters.watch() });
  };
  // #endregion Services

  return (
    <FormProvider methods={filters}>
      <Container maxWidth={themeStretch ? false : "xl"}>
        <Helmet>
          <title>Adam Radar | Mercury news dashboard</title>
        </Helmet>
        {/* Charts */}
        <AppLoadingAndErrorWrapper
          isLoading={isDataLoading || isLoadingOptions}
          errorMessage={null}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MercuryNewsMainDashboardHeader
                reset={filters.reset}
                enableFetch={!!platformData}
                filterData={filtersData!}
                downloadCsv={handleDownloadCsv}
                isDownloadingCsv={isLoading}
                setParameters={setParameters}
              />
            </Grid>
            <Grid item container spacing={3} xs={12}>
              {platformData?.analysis.cards?.length && (
                <DashboardCards cards={platformData?.analysis.cards} />
              )}
            </Grid>
            {platformData?.analysis.pieChart && (
              <DashboardPieChart
                theme={theme}
                data={platformData?.analysis.pieChart}
                xs={12}
                md={6}
              />
            )}
            {platformData?.analysis.lineChart && (
              <DashboardLineChart data={platformData?.analysis.lineChart} xs={12} md={6} />
            )}
            {platformData?.analysis.top5Words && (
              <DashboardTopWords data={platformData?.analysis.top5Words} xs={12} md={6} />
            )}
            {platformData?.analysis.barChart && (
              <DashboardBarChart
                theme={theme}
                data={platformData?.analysis.barChart}
                xs={12}
                md={6}
              />
            )}
            {platformData?.analysis.wordCloudChart && (
              <Grid item xs={12} md={12}>
                <ParentSize>
                  {({ width, height }) => (
                    <DashboardWordCloud
                      data={platformData?.analysis?.wordCloudChart?.content!}
                      xs={12}
                      md={12}
                      width={width}
                      height={600}
                    />
                  )}
                </ParentSize>
              </Grid>
            )}
          </Grid>
        </AppLoadingAndErrorWrapper>
      </Container>
    </FormProvider>
  );
};

export default MercuryDashboard;
