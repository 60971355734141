import Image from 'src/components/image/Image';
import { Box, Grid, Stack, Divider, Container, Typography, IconButton } from '@mui/material';
import Iconify from '../../components/iconify';
import logo from '../../assets/flower-logo.svg';

const socials = [
  {
    value: 'facebook',
    name: 'FaceBook',
    icon: 'eva:facebook-fill',
    color: '#1877F2',
  },
  {
    value: 'youtube',
    name: 'Youtube',
    icon: 'ant-design:youtube-filled',
    color: '#E02D69',
  },
  {
    value: 'linkedin',
    name: 'Linkedin',
    icon: 'eva:linkedin-fill',
    color: '#007EBB',
  },
  {
    value: 'twitter',
    name: 'Twitter',
    icon: 'eva:twitter-fill',
    color: '#00AAEC',
  },
];

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />

      <Container sx={{ pt: 10 }}>
        <Box
          display="flex"
          alignItems={{
            xs: 'center',
            md: 'flex-end',
          }}
          flexDirection={{
            xs: 'column',
            md: 'row',
          }}
          justifyContent="space-between"
          textAlign={{
            xs: 'center',
            md: 'left',
          }}
        >
          <Box
            alignItems={{ xs: 'center', md: 'flex-start' }}
            display="flex"
            flexDirection="column"
            sx={{ mb: 3 }}
          >
            <Image src={logo} sx={{ width: '200px' }} />
            <Typography
              variant="body2"
              sx={{ width: '50%', mb: 2 }}
              color="#000000"
              fontSize={14}
              fontFamily="poppins"
            >
              In Adam AI, we are on a mission of making Islam more known and accessible to people.
              We capitalize on the powers of intelligent AI and cutting-edge technologies to create
              products and solutions that communicate the true essence of Islam.
            </Typography>
          </Box>

          <Grid item xs={12} md={6} sx={{ mb: 3 }}>
            <Stack
              spacing={1}
              direction="row"
              justifyContent={{
                xs: 'center',
                md: 'flex-start',
              }}
            >
              {socials.map((social, index) => (
                <IconButton
                  key={index}
                  href="#"
                  sx={{
                    '&:hover': {
                      background: 'transparent',
                    },
                  }}
                >
                  <Iconify icon={social.icon} />
                </IconButton>
              ))}
            </Stack>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
