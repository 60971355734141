import { Grid } from '@mui/material';
import { DashboardTopWordsPropsType } from 'src/types/dashboardSectionsPropsTypes';
import { Top5Words } from '../@dashboard/general/app';

const DashboardTopWords = ({ data, xs, md }: DashboardTopWordsPropsType) => {
  return (
    <Grid item xs={xs} md={md} display='flex'>
      <Top5Words style={{width: '100%'}} content={data?.content || []} title={data?.title} />
    </Grid>
  );
};

export default DashboardTopWords;
