// @mui
import { styled } from '@mui/material/styles';
import { Card, CardHeader, Typography, Stack, StackProps, Divider } from '@mui/material';
// types
import { top5WordsType } from 'src/types/dashboardData';
// utils
import { fShortenNumber } from '../../../../utils/formatNumber';
// components
import Scrollbar from '../../../../components/scrollbar';

// ----------------------------------------------------------------------

const StyledBlock = styled((props: StackProps) => (
  <Stack direction="row" alignItems="center" {...props} />
))({
  minWidth: 72,
  flex: '1 1',
});

// ----------------------------------------------------------------------

type ItemProps = {
  id: string;
  text: string;
  value: number;
};

export default function Top5Words({ content, title, style }: top5WordsType) {
  return (
    <Card style={style}>
      <CardHeader title={title} />
      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3 }}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <StyledBlock sx={{ minWidth: 120 }}>
              <Typography variant="subtitle2">Word</Typography>
            </StyledBlock>
            <StyledBlock sx={{ minWidth: 88 }}>
              <Typography variant="body2">Count</Typography>
            </StyledBlock>
          </Stack>
          {content?.map((country: any, index: number) => {
            return <WordItem key={index} word={country} />;
          })}
        </Stack>
      </Scrollbar>
    </Card>
  );
}

// ----------------------------------------------------------------------

type WordItemProps = {
  word: ItemProps;
};

function WordItem({ word }: WordItemProps) {
  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2}>
        <StyledBlock sx={{ minWidth: 120 }}>
          <Typography variant="subtitle2">{word.text}</Typography>
        </StyledBlock>
        <StyledBlock sx={{ minWidth: 88 }}>
          <Typography variant="body2">{fShortenNumber(word.value)}</Typography>
        </StyledBlock>
      </Stack>
      <Divider />
    </>
  );
}
