import { Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Controller } from 'react-hook-form';

const CustomStartDate = ({ control }: { control: any }) => {
  return (
    <Grid item xs={12} md={3} sm={6}>
      <Controller
        name="startDate"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <DatePicker
            {...field}
            inputFormat='yyyy-MM-dd'
            label="Start date"
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
        )}
      />
    </Grid>
  );
};

export default CustomStartDate;
