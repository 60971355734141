import { Button, Grid } from "@mui/material";
import Iconify from "src/components/iconify/Iconify";

type SearchBarButtonsPropsType = {
  reset: any;
};

const SearchBarButtons = ({ reset }: SearchBarButtonsPropsType) => {

  return (
    <Grid item xs={12} sm={6} md={6}>
      <Button
        variant='contained'
        color='error'
        fullWidth
        onClick={reset}
        startIcon={<Iconify icon='eva:trash-2-outline' />}
        sx={{ height: "100%" }}
      >
        Clear
      </Button>
    </Grid>
  );
};

export default SearchBarButtons;
