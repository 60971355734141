import { Helmet } from 'react-helmet-async';
// @mui
import { Typography } from '@mui/material';

export default function ComingSoonPage() {

  return (
    <>
      <Helmet>
        <title> Coming Soon </title>
      </Helmet>
      <Typography variant="h3" paragraph>
        Coming Soon!
      </Typography>
      <Typography sx={{ color: 'text.secondary' }}>
        We are currently working hard on this page!
      </Typography>
    </>
  );
}