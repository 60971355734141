import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { AxiosError } from "axios";
import React, { ReactNode } from "react";
import { SxProps } from "@mui/material";
import LoadingScreen from "../loading-screen/LoadingScreen";

interface IAppLoadingAndErrorWrapper {
  isLoading: boolean;
  errorMessage: AxiosError | null;
  children: ReactNode;
  sx?: SxProps;
  showLinear?: boolean;
}

const AppLoadingAndErrorWrapper: React.FC<IAppLoadingAndErrorWrapper> = props => {
  const { isLoading, errorMessage, children, sx, showLinear } = props;

  if (isLoading) {
    console.log("Loading...");
    return (
      <Grid
        container
        alignItems='center'
        justifyContent='center'
        sx={{ height: { xs: "inherit", md: "75vh" }, ...sx }}
      >
        <LoadingScreen showImage={false} showLinear={showLinear} />
      </Grid>
    );
  }

  if (errorMessage) {
    console.log("Error...");
    return (
      <Grid container alignItems='center' justifyContent='center' sx={{ height: { md: "75vh" } }}>
        <Typography color='error' variant='h4'>
          {errorMessage.message}
        </Typography>
      </Grid>
    );
  }

  console.log("Rendering...");
  return <>{children}</>;
};

export default AppLoadingAndErrorWrapper;
